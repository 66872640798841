<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-26 15:44:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-09-27 14:22:16
 * @FilePath: \official_website\src\views\componets\Breadcrumb.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="newBreadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/medium' }"
        >媒体中心</el-breadcrumb-item
      >
      <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {

    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
    
<style lang="scss">
#newBreadcrumb {
  width: 100%;
  padding: 1rem 18.5%;
  .el-icon-arrow-right {
    color: #666;
  }
}
</style>