<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-08 14:21:55
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 13:43:14
 * @FilePath: \official_website\src\views\medium\MediumDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 新闻详情页 -->
<template>
  <div id="mediumDetail">
    <Header></Header>
    <div class="new-breadcrumb">
      <NewBreadcrumb></NewBreadcrumb>
    </div>
    <div class="medium-box">
      <div id="img-content" class="rich_media_wrp">
        <h1 class="rich_media_title" id="activity-name">
          <!-- “创新加速，智享未来”麓山病理AI创新发展高峰论坛圆满召开 -->
          {{ newsInfo.title }}
        </h1>
        <div class="news-content" v-html="newsInfo.content"></div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import NewBreadcrumb from "../componets/NewBreadcrumb.vue";
import api from "../../api/api";
export default {
  name: "mediumDetail",
  props: {},
  components: {
    Header,
    Footer,
    NewBreadcrumb,
  },
  data() {
    return {
      newsInfo: {},
    };
  },
  created() {
    let id = this.$route.params.id;
    // console.log(id);
    this.getNewsDetail(id);
  },
  mounted() {
  
  },
  watch: {},
  methods: {
    getNewsDetail(id) {
      api
        .getNewsById(id)
        .then((res) => {
          console.log(res.data);
          this.newsInfo = res.data.models[0];
        })
        .catch((error) => {});
    },
  },
};
</script>
    
<style lang="scss">
#mediumDetail {
  .new-breadcrumb {
    padding-top: 5rem;
  }
  .medium-box {
    position: relative;
    width: 65%;
    // padding-top: 5rem;
    margin: auto;
    .rich_media_title {
      font-size: 1.375rem;
      // line-height: 1.4;
      margin-bottom: 1rem;
      text-align: center;
    }
    .news-content {
      // white-space: pre-wrap;
      font-family: "Microsoft Yahei";
      font-size: 1.125rem;
      color: #333;
      p {
        line-height: 2;
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
  .news-content {
    img {
      max-width: 100%;
      display: block;
      margin: 20px auto;
    }
    // p {
    //   font-family: "OPlusSans3-Regular";
    // }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
  }
}
</style>